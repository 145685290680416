import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="Our Approach" />
    {/* <Container className="d-lg-flex heroWidth">
        <div lg={6} className="">
          <h1></h1>
          
        </div>
        <div className="">
          <StaticImage
            src="../images/child-white-shirt.jpg"
            loading="eager"
            width={350}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Child in white shirt talking into a microphone"
          />
        </div>
    </Container> */}
    <div className="masthead mb-5">
      <Container className="singleCol">
        <h1>Our Speech Therapy Approach</h1>
      </Container>
    </div>
    <Container className="singleCol ">
    {/* <p className="float-img float-none float-md-start me-md-3">
        <StaticImage 
          src="../images/kris-langley.jpg"
          alt="Michael Kaufman"
          placeholder="blurred"
          className="float-img text-center"
        /> */}
      {/* </p> */}
      <p className="lead">
        Client relationships are at the center of everything we do. The relationship we have with you is equally as important as your speech therapy goals. 
      </p>
      <p>
        Short and long-term goals are created. Then, home programs are developed to support weekly progress. Thereafter, frequent updates on performance and progress help you track growth.
      </p>
      {/* <p>
      Rather than driving to appointments or having someone come into your home, our virtual speech therapy sessions give you the privacy and flexibility you need. Serving individuals and families virtually allows us to be anywhere you need us to be!
      </p> */}
      <p className="text-center">
        <StaticImage 
          src="../images/kids-together.jpg"
          alt="Michael Kaufman"
          placeholder="blurred"
          className="text-center"
        />
      </p>
      <h2>Multi-Disciplinary</h2>
      <p>We are happy to consult and collaborate with doctors, teachers, therapists, and specialists. The relationship with other providers is important to us, specifically to ensure we are working together to meet your needs. Chiefly, the focus is on comprehensive and holistic speech therapy sessions with outcomes that exceed your expectations!</p>

      <h2>Academic Advocacy</h2>
      <p>We align our speech therapy goals to core curriculum and educational standards. Furthermore, we can help you navigate school systems, advocate for your child, and attend Individualized Education Plan (IEP) meetings. </p>
      <p>At Vibe Speech Therapy, our therapists maintain their certificate of clinical competence (CCC) from the American Speech-Language Hearing Association and are licensed speech and language therapists.  </p>
    </Container>
    


  </Layout>
)

export default IndexPage
